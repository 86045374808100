.header-content-container{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10rem; 
    color: white;
}

.header-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.type-writer{
    background: #7F7FD5; 
    background: -webkit-linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5);  
    background: linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
}

.icon-scroll,
.icon-scroll::before {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.icon-scroll {
    width: 40px;
    height: 70px;
    border: 2px solid #fff;
    border-radius: 25px;
}

.icon-scroll::before {
    content: '';
    width: 8px;
    height: 8px;
    background: #fff;
    margin-left: -4px;
    top: 15px;
    border-radius: 50%;
    animation: 1.5s scroll cubic-bezier(0.65, -0.55, 0.25, 1.5) infinite;
}

@keyframes scroll {
    0% {
        opacity: 0;
        transform: translateY(0) scale(0.5);
    }
    10% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: translateY(30px) scale(0.5);
    }
}

a{
    position: relative;
    z-index: 99999999;
}

.icons-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    font-size: 25px;
}
.icons-div{
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #000616;
}
.icons-div a{
    color: white;
}
.icons-div:hover{
    background-color: #020c2b;
}
.icons-div a:hover{
    scale: 1.1;
    transition: all .4s ease;
}


/* intro page */

.intro-container{
    display: flex;
    justify-content: center; 
    align-items: center;
    gap: 2rem;    
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: rgba(0, 0, 0, .7);
    color: white;
}

.profile-image{
    width: 250px;
    height: 250px;
    border-radius: 50%;
    padding: 1rem;
}

.text-area{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 2rem 0rem;
}


/* projects */ 

.project-main-container{
    width: 50vw;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.project-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.project-container h1{
    font-size: 16px;
}
.project-container span {
    font-size: 12px;
}
.project-box {
    display: flex;
    gap: .5rem;
    cursor: pointer;
    border: 3px solid transparent ;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, .7);
    color:white
}

.project-box img{
    width: 350px;
    border-radius: 6px;
}
.project-box a {
  background-color: transparent;
  border: 2px solid #7F7FD5;
  width: 200px;
  text-align: center;
  padding: .5rem .2rem;
  color: white;
  text-decoration: none;
  font-size: 15px;
}

.project-box:hover{
    scale: 1.1;
    transition: all .5s ease-in-out;
}

.project-box a:hover{
    background-color: #7F7FD5;
    transition: all .3s ease;
}

.box-content{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: .5rem;
}



/* Responsive */ 

/* MOBILE DEVICES */ 
@media screen and (min-width:320px) and (max-width: 480px){
    .icons-container{
        font-size: 15px;
        gap: 1rem;
    }
    .icons-div{
        width: 30px;
        height: 30px;
    }
    .intro-container{
        flex-direction: column;
        width: 100vw;
        padding: .5rem 2rem;
        gap: .5rem;
    }
    .intro-container h1{
        font-size: 16px;
    }
    .profile-image{
        width: 150px;
        height: 150px;
    }
    .project-main-container{
        font-size: 8px;
        width: 100vw;
        max-height: 100vh;
        padding: 1rem;
    }
    .project-container{
        gap: .5rem;
    }
    .project-box img{
        display: none;
    }
    .project-box h1{
        font-size: 12px;
    }
    .project-box span{
        font-size: 10px;
    }
    .project-box a{
        font-size: 10px;
        width: 100px;
        padding: 0rem;
        border-width: 1px;
    }
}

/* iPADS, TABLETS */ 
@media screen and (min-width:481px) and (max-width: 768px){
    .intro-container{
        flex-direction: column;
        width: 100vw;
        padding: 0rem 2rem;
    }
    .intro-container h1{
        font-size: 16px;
    }
    .profile-image{
        width: 150px;
        height: 150px;
    }
    .project-container{
        gap: .5rem;
    }
    .project-box img{
        display: none;
    }
    .project-box h1{
        font-size: 12px;
    }
    .project-box span{
        font-size: 10px;
    }
    .project-box a{
        font-size: 10px;
        width: 100px;
        padding: 0rem;
        border-width: 1px;
    }
}

/* SMALL SCREENS, LAPTOPS */
@media screen and (min-width:769px) and (max-width: 1024px){
    .intro-container{
        width: 100vw;
        padding: 0rem 2rem;
    }
    .intro-container h1{
        font-size: 16px;
    }
    .profile-image{
        width: 200px;
        height: 200px;
    }
    .project-box img{
        display: none;
    }
}

/* DESKTOPS, LARGE SCREENS */ 
@media screen and (min-width:1025px) and (max-width: 1200px) {
    .intro-container{
        width: 100vw;
        padding: 0rem 2rem;
    }
    .project-main-container{
        width: 80vw;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1920px) {
    .intro-container{
        width: 70vw;
        padding: 0rem 2rem;
    }
}